.newness-dashboard-page {
  .dart-page-title{
    .center{
      .dart-icon{
        margin-top: -4px;
      }
    }
  }
  hr{
    border: none;
    border-bottom: 1px solid #E1E2E3;
    margin-bottom: 50px;
  }
}

// used for top header
.main-layout-content {
  .newness-dashboard-page{
    padding: 0 64px;
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";