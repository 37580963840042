.api-logs-list-view {
  padding-top: 32px;
  .api-log-table-cell{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .time-cell {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > span:first-child {
        color: @dart-dark;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
      > span:last-child{
        color: @dart-dark;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";