.selected-style-color-block {
  position: relative;
  display: flex;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  &.light{
    background-color: white;

    .remove-wrapper{
      .dart-icon {
        * {
          fill: black;
        }
      }
    }
    .cc-info{
      .left{
        .top{
          span{
            color: @dart-dark;
            border-right: 1px solid @dart-mid-gray;
          }
        }
        .bottom{
          span{
            color: @dart-dark;
            border-right: 1px solid @dart-mid-gray;
          }
        }
      }
      .right{
        .value{
          color: #000000;
        }
        .label{
          color: @dart-mid-gray;
        }
      }
    }
  }
  &.dark{
    background-color: @dart-dark;

    .remove-wrapper{
      .dart-icon {
        * {
          fill: white;
        }
      }
    }
    .cc-info{
      .left{
        .top{
          span{
            color: white;
            border-right: 1px solid @dart-dark;
          }
        }
        .bottom{
          span{
            color: white;
            border-right: 1px solid @dart-dark;
          }
        }
      }
      .right{
        .value{
          color: #ffffff;
        }
        .label{
          color: @dart-dark;
        }
      }
    }
  }
  .cc-info{
    display: flex;
    justify-content: space-between;
    width: 100%;
    .left{
      .top{
        span{
          font-family: 'GothamLight', sans-serif;
          font-weight: 450;
          font-size: 12px;
          line-height: 12px;
          padding: 0 8px;
          &.bold{
            font-family: 'GothamRegular', sans-serif;
            text-transform: uppercase;
          }
          &:first-child{
            padding-left: 0;
          }
          &:last-child{
            padding-right: 0;
            border-right: none;
          }
        }
      }
      .bottom{
        span{
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          padding: 0 8px;
          &:first-child{
            padding-left: 0;
          }
          &:last-child{
            padding-right: 0;
            border-right: none;
          }
        }
      }
    }
    .right{
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 24px;
      padding-right: 48px;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .value{
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
      }
      .label{
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .remove-wrapper{
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";