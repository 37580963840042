.newness-upload {
  .ant-alert {
    margin-bottom: 20px;
  }
  .file-upload-form {
    .ant-form-item-control-input-content {
      > span {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
    .ant-upload-list{
      display: none;
    }
    .is-upload-in-progress{
      .ant-upload-list{
        display: block;
      }
    }
    .valid {
      height: 112px;
    }
  }

  .ant-form-item {
    padding: 16px 16px;
    border: 1px solid @dart-gray;
    border-radius: @dart-border-radius-md;
    transition: 0.2s;
    .dart-pill-select{
      .ant-tag{
        width: 100%;
      }
    }
    .ant-upload-list-item-progress {
      padding: 0;
      margin: 0;
    }

    .ant-progress-line {
      margin-top: 20px;
    }

    .ant-upload {
      width: 100%;

      button {
        width: 100%;
      }
    }

    .ant-form-item-required {
      transition: 0.2s;
    }

    &.valid {
      border: 1px solid @dart-secondary;
      transition: 0.2s;

      .ant-form-item-required {
        color: @dart-secondary;
        transition: 0.2s;
      }
    }

    &.has-error {
      border: 1px solid @dart-danger;
      transition: 0.2s;

      .ant-form-item-required {
        color: @dart-danger;
        transition: 0.2s;
      }
    }
  }

  .ant-upload-list-item {
    margin: 0;
  }

  .ant-upload-text-icon {
    display: none;
  }

  .ant-upload-list-item-name {
    padding-left: 0;
    color: @dart-secondary;
  }

  .ant-upload-disabled {
    opacity: 0.4;
  }

  .ant-upload-list-item .ant-upload-list-item-info {
    display: none;
  }

  .ant-upload-list-item-card-actions .anticon {
    color: @dart-dark;
    margin-right: 10px;
  }

  .ant-upload-list-item-progress {
    bottom: 0;

    .ant-progress-inner {
      height: 20px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      .ant-progress-bg{
        height: 20px !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .ant-progress-success-bg{
        height: 20px !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .ant-modal-footer .ant-btn.ant-btn-default {
    display: none;
  }
}

.newness-upload-modal{
  .ant-modal-title{
    font-family: 'GothamRegular', sans-serif;

    font-weight: 450;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #333E47;
  }
  .ant-modal-close-x{
    height:45px;
    width: 45px;
    line-height: 45px;
  }
  .dart-pill-select{
    text-transform: uppercase;
  }
  label {
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 12px;
    line-height: 18px;
    color: @dart-dark;
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";