.user-list-card-item {
  background-color: #F6F6F6;
  border-radius: 16px;
  width: 350px;
  display: inline-block;
  border: 1px solid #F6F6F6;

  &:hover{
    .top .actions .edit-button{
      opacity: 1;
      transition: 0.2s;
    }
  }

  &.disabled {
    border: 1px solid red;
  }

  hr {
    margin: 0;
    border: 0;
    border-bottom: 1px solid @dart-gray-alt;
  }

  .top {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
    padding: 16px 16px;
    column-gap: 16px;

    .left {
      position: relative;

      .ant-avatar {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid @dart-mid-gray;
        background: white;
      }

      .you-indicator {
        position: absolute;
        top: -5px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 2px 8px;
        width: 40px;
        border-radius: 8px;
        background-color: @dart-primary;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 12px;
        font-family: 'GothamBold', sans-serif;
        font-weight: 400;
      }
    }

    .right {
      .name {
        font-family: 'GothamBold', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: @dart-dark;
        white-space: nowrap;
        max-width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .position {
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 13px;
        line-height: 24px;
        color: @dart-mid-gray;
        white-space: nowrap;
        max-width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .badges {
        display: flex;
        flex-direction: row;
        column-gap: 4px;
      }

      .role-badge,
      .region-badge {
        background-color: @dart-dark;
        color: white;
        border-radius: 8px;
        padding: 2px 6px;
        width: fit-content;
      }
    }

    .actions {
      position: absolute;
      right: 0;
      top: 0;

      .edit-button {
        opacity: 0;
        border: 0;
        box-shadow: none;

        * {
          fill: @dart-dark;
          transition: 0.2s;
        }

        &:hover {
          * {
            fill: @dart-primary;
            transition: 0.2s;
          }
        }
      }
    }
  }

  .bottom {
    padding: 8px 16px;

    h4 {
      font-family: 'GothamBold', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: @dart-mid-gray;
      text-align: left;
    }

    .email {
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 14px;
      line-height: 24px;
      color: @dart-dark;
      text-align: left;
      display: flex;
      align-items: center;
      column-gap: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .phone {
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 14px;
      line-height: 24px;
      color: @dart-dark;
      text-align: left;
      display: flex;
      align-items: center;
      column-gap: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";