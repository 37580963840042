.manual-replenishment-step-two {
  .dart-page-title {
    height: 116px;
    margin-top: 0;
    z-index: 1;
    gap: 20px;
    flex-direction: column;

    .center {
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: @dart-dark;
    }

    .right {
      position: initial;

      .replenishment-steps-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 6px;

        .replenishment-step {
          width: 163px;
          height: 4px;
          border-radius: 2px;
          background-color: @dart-gray;
          &.danger {
            background: @dart-danger !important;
          }
          &.active {
            background-color: @dart-light-blue;
          }
        }
      }
    }
  }

  .container-content > .top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .left{
      .page-summary-content {
        width: 180px;
        z-index: 9;
      }
    }

    .right{
      display: flex;
      column-gap: 16px;
      .dart-button.md .ant-btn {
        padding: 16px 32px;
        height: auto;
        font-size: 16px;
        line-height: 16px;
        font-weight: 450;
        z-index: 9;
      }
    }
  }

  .container-content > hr {
    border: none;
    border-bottom: 1px solid #E1E2E3;
    margin-top: 16px;
    margin-bottom: 32px;
    height: 0;
  }
}

// used for top header
.main-layout-content {
  .manual-replenishment-step-two {
    padding-top: 64px;

    .container-content {
      padding: 72px 64px 16px 64px;
    }

    .dart-page-title {
      background-color: @dart-light;
      position: fixed;
      width: 100vw;
      top: 94px;
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";