.store-profile-header {
  padding-top: 48px;
  padding-bottom: 40px;
  font-family: 'GothamLight', sans-serif;
  font-weight: 325;
  font-size: 12px;
  line-height: 18px;
  position: sticky;
  top: 0;
  z-index: 96;
  background: white;

  .top-section {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-button {
      border: none;
      box-shadow: none;
      margin-left: -6px;

      &:hover,
      &:focus {
        color: @dart-dark;
      }
    }

    .delete-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 12px;
      border: none;
      box-shadow: none;
      margin-right: -12px;

      span:focus,
      span:hover,
      &:hover,
      &:focus {
        color: @dart-dark;
      }

      p {
        color: #000000;
        margin: 0;
        padding: 0;
      }
      .anticon{
        padding-top: 2px;
      }
    }
  }

  .middle-section {
    margin-bottom: 24px;

    .store-name {
      font-family: 'GothamRegular', sans-serif;

      font-weight: 450;
      font-size: 32px;
      line-height: 32px;
      text-transform: uppercase;
      color: @dart-dark;
      > span {
        display: flex;
      }
      .small-splitter{
        color: @dart-gray;
        font-family: sans-serif;
        font-weight: 300;
        margin-left: 12px;
        margin-right: 12px;
        font-size: 26px;
        margin-top: -2px;
      }
    }

    .store-location {
      margin-top: 8px;
      color: #000000;
      text-transform: capitalize;
    }
  }

  .splitter {
    border: none;
    border-bottom: 1px solid @dart-gray;
    margin: 0;
  }

  .bottom-section {
    margin-top: 16px;
    display: flex;
    column-gap: 32px;
    .store-status {
      color: @dart-dark;
      padding-left: 20px;
      position: relative;
      &.LIVE:before ,
      &.PAUSED:before ,
      &.CLOSED:before ,
      &.PLANNING:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 2px;
      }
      &.LIVE:before {
        background-color: #19945D;
      }
      &.PAUSED:before {
        background-color: #FFDD00;
      }
      &.CLOSED:before {
        background-color: #EF1919;
      }
      &.PLANNING:before {
        background-color: #5FC7CB;
      }
    }

    .store-status-extra {
      color: @dart-dark;
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";