.store-allocation-settings{
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  &.disabled{
    &:before{
      content: "";
      height: calc(100% - 40px);
      width: 100%;
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 1;
      backdrop-filter: blur(2px);
      background-color: transparent;
      cursor: not-allowed;
    }
  }

  .ant-form-item-row{
    flex-direction: column;
  }

  form > .ant-row{
    margin-bottom: 32px;
  }
  h2, h3{
    font-family: 'GothamRegular', sans-serif;

    font-weight: 450;
    text-transform: uppercase;
    color: @dart-dark;
    margin-bottom: 24px;
    margin-top: 0;
  }

  .dates-column{
    .ant-form-item{
      margin-bottom: 40px
    }
  }

  h2{
    font-size: 20px;
    line-height: 16px;
    height: 16px;
    span{
      font-size: 11px;
      text-align: right;
      float: right;
      color: @dart-secondary;
    }
  }
  h3{
    font-size: 16px;
    line-height: 20px;
    height: 20px;
  }

  hr.light-splitter{
    border: none;
    border-bottom: 1px solid @dart-gray-alt;
    margin: 8px 0 34px;
  }

  .note-column {
    .dart-input .ant-input::placeholder{
      font-family: 'GothamLight', sans-serif;

      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: @dart-dark;
    }
  }

  .ant-form-item-label{
    display: block;
    text-align: left;
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-bottom: 8px;
    label{
      height: 16px;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
  .ant-radio-inner::after {
    background-color: @dart-secondary;
  }
  .ant-radio-checked .ant-radio-inner{
    border: 4px solid @dart-secondary;
  }
  .ant-radio-checked::after{
    border: 4px solid @dart-secondary;
  }
  .ant-radio-group-large{
    display: flex;
    width: fit-content;
  }
  .ant-radio-wrapper{
    display: flex;
    flex: 1;
    align-items: center;
    * {
      box-shadow: none !important;
      outline: none !important;
    }
  }
  .ant-radio-inner{
    border: 2px solid @dart-gray;
    height: 32px;
    width: 32px;
  }
  span.ant-radio{
    margin-top: -8px;
    + span {
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(1);
  }
  span.ant-radio + *{
    padding-left: 16px;
    padding-right: 16px;
    line-height: 32px;
    white-space: nowrap;
  }
  .ant-col .ant-form-item:last-child{
    margin-bottom: 0;
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";