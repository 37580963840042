.newness-upload-list-layout {
  .flow-doc-live-edit-upload{
    .ant-form-item{
      margin: 0;
    }
    .newness-upload .ant-form-item{
      border: none;
      padding: 0;
      .ant-form-item-control-input{
        height: 32px;
      }
      .dart-button.md .ant-btn{
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  .upload-file-init-button{
    display: flex;
    column-gap: 10px;
  }
  .minimized-upload-progress {
    width: 206px;
    position: absolute;
    top: 36px;

    .chunks-wrapper {
      .chunk {
        height: 10px !important;
      }
    }
  }

  .newness-upload-list-filters {
    margin-bottom: 34px;
  }

  hr {
    border: 0;
    border-bottom: 1px solid #E1E2E3;
  }
}

.newness-upload-modal {
  .ant-upload-select{
    .dart-button{
      > span { width: 100% }
    }
  }

  .ant-modal-body {
    padding-bottom: 0;
  }

  .ant-modal-footer {
    padding-left: 24px;
    padding-right: 24px;
  }

  .newness-upload-modal-footer {
    display: flex;
    justify-content: right;

    &.align-left {
      justify-content: left;
      margin-left: -24px;

      .dart-button.md .ant-btn {
        padding-left: 24px;
      }
    }
  }
}

// used for top header
.main-layout-content {
  .newness-upload-list-layout{
    padding: 0 64px;
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";