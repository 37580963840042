.allocation-history-wrapper {
  .history-list-wrapper-block{
    width: 100%;
    margin: 0 auto;
  }
  .filter-allocation-button-wrap {
    margin-right: 32px;
     display: flex;
     align-items: center;
     justify-content: center;
     .vertical-splitter{
       width: 1px;
       height: 24px;
       background-color: @dart-mid-gray;
       margin: 0 8px;
     }
     .filter-input-wrapper {
       display: flex;
       justify-content: center;
       align-items: center;
       span.label {
         font-family: 'GothamRegular', sans-serif;
         font-weight: 450;
         font-size: 14px;
         line-height: 14px;
         color: @dart-mid-gray;
         margin-right: 8px;
       }
       .dart-compact-select{
         margin-top: 3px;
       }
     }
   }
  .resume-delete-allocation-button-wrap{
    position: absolute;
    right: 40px;
    top: 13px;
    z-index: 2;
    display: flex;
    column-gap: 8px;
    &.loading{
      top: 18px;
    }

  }
  .allocation-history-details {
    .modal-header{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
    }
    .details-header{
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      min-height: 50px;
      .filters{
        display: flex;
        column-gap: 16px;
      }
    }
    .ant-modal-title{
      font-family: "GothamLight", sans-serif;
      font-size: 14px;
    }
    .ant-modal .ant-modal-content {
      border-radius: 16px;
      padding-bottom: 16px;
      .ant-modal-header{
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      .ant-modal-body {
        padding: 0;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        .expand-toggle-button {
          padding-left: 16px;
        }
      }

      .review-ccs-wrapper {
        height: unset;

        .dart-table.border-top {
          border-top: none !important;
        }
      }
    }
  }
  .ant-tabs-nav{
    margin-top: 34px;
    margin-bottom: 34px;
    &:before{
      display: none;
    }
    .ant-tabs-ink-bar{
      display: none;
    }
    .ant-tabs-tab{
      font-family: 'GothamRegular', sans-serif;

      font-weight: 450;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      .ant-tabs-tab-btn {
        color: @dart-mid-gray;
      }
      &.ant-tabs-tab-active{
        .ant-tabs-tab-btn {
          color: @dart-primary;
        }
      }
    }
  }

  .skeleton-wrapper{
    .skeleton-item{
      border-radius: 16px;
      background-color: #F6F6F6;
      position: relative;
      width: 100%;
      height: 123px;
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";