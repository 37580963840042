.dart-checkbox {
  display: flex;
  align-items: center;

  .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) {
    &::after {
      border-color: @dart-secondary;
      border-width: 2px;
    }

    .ant-checkbox-inner {
      background-color: @dart-secondary;
      border-color: transparent;
      border-width: 2px;
    }
  }

  &.withoutDisabledStyle .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner{
    background-color: fade(@dart-secondary, 100%) !important;
  }

  .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: fade(@dart-secondary, 20%);
      border-color: transparent !important;
      border-width: 2px;
    }
  }

  .ant-checkbox:not(.ant-checkbox-disabled) {
    .ant-checkbox-inner {
      background-color: white;
      border-color: @dart-gray;
      border-width: 2px;
      border-style: solid;

      &::after {
        border-color: white
      }
    }
  }

  .ant-checkbox.ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: @dart-light;
      border-width: 2px;

      &::after {
        border-color: white
      }
    }
  }

  &.xs {
    .checkbox-title {
      font-family: 'GothamBold', sans-serif;
      font-weight: 450;
      font-size: 16px;
      line-height: 16px;
      color: @dart-dark;
      padding-left: 12px;
    }

    .ant-checkbox-inner {
      border-radius: 6px;
      height: 18px;
      width: 18px;

      &::after {
        border-width: 1.5px;
        width: 4px;
        height: 9px;
        margin-top: -1px;
      }
    }

    .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) {
      &::after {
        border-radius: 6px;
      }
    }
  }

  &.sm {
    .checkbox-title {
      font-family: 'GothamBold', sans-serif;
      font-weight: 450;
      font-size: 16px;
      line-height: 16px;
      color: @dart-dark;
      padding-left: 12px;
    }

    .ant-checkbox-inner {
      border-radius: 6px;
      height: 24px;
      width: 24px;

      &::after {
        border-width: 1.5px;
        width: 6px;
        height: 12px;
        margin-top: -1px;
      }
    }

    .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) {
      &::after {
        border-radius: 6px;
      }
    }
  }

  &.md {
    .checkbox-title {
      font-family: 'GothamBold', sans-serif;
      font-weight: 450;
      font-size: 16px;
      line-height: 16px;
      color: @dart-dark;
      padding-left: 16px;
    }

    .ant-checkbox-inner {
      border-radius: @dart-border-radius-sm;
      height: 32px;
      width: 32px;

      &::after {
        border-width: 2px;
        width: 8px;
        height: 16px;
        margin-top: -2px;
        margin-left: 1px;
      }
    }

    .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) {
      &::after {
        border-radius: @dart-border-radius-sm;
      }
    }
  }

  &.lg {
    .checkbox-title {
      font-family: 'GothamBold', sans-serif;
      font-weight: 450;
      font-size: 16px;
      line-height: 16px;
      color: @dart-dark;
      padding-left: 16px;
    }

    .ant-checkbox-inner {
      border-radius: 10px;
      height: 40px;
      width: 40px;

      &::after {
        border-width: 2.5px;
        width: 12px;
        height: 22px;
        margin-top: -2px;
        margin-left: 1px;
      }
    }

    .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) {
      &::after {
        border-radius: 10px;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";