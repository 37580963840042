.selected-style-color-block-advanced {
  position: relative;
  display: flex;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  height: 108px;
  overflow-x: auto;

  &.open {
    border-radius: 16px 16px 0 0;
  }

  + .collapse-child-render {
    background: #FAFAFA;
  }

  &.light {
    background-color: white;

    .remove-wrapper {
      .dart-icon {
        * {
          fill: black;
        }
      }
    }

    .cc-info {
      .left {
        .top {
          margin-top: 6px;

          span {
            border-right: 1px solid @dart-mid-gray;
            font-family: 'GothamBook', sans-serif;
            font-weight: 325;
            font-size: 16px;
            line-height: 16px;
            color: @dart-dark;
            white-space: nowrap;

            &.bold {
              font-family: 'GothamRegular', sans-serif;
              font-weight: 400;
              text-transform: uppercase;
            }
          }
        }

        .bottom {
          white-space: nowrap;

          span {
            color: @dart-dark;
            border-right: 1px solid @dart-mid-gray;
          }
        }
      }

      .right {
        .value {
          color: #000000;
          font-family: 'GothamBook', sans-serif;
          font-weight: 325;
          font-size: 20px;
          line-height: 30px;

          &.editable {
            border-bottom: 1px solid @dart-dark;
            min-width: 42px;
            text-align: center;
            padding: 0;

            .dart-editable-cell {
              cursor: pointer;
            }

            .edit-mode-wrapper {
              height: 40px;
            }
          }

          &.multiple {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            column-gap: 8px;

            .value-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              flex: 1;
              width: 50px;

              > span {
                font-family: 'GothamBook', sans-serif;
                font-weight: 325;
                font-size: 12px;
                line-height: 18px;

                &:first-child {
                  color: @dart-dark;
                }

                &:last-child {
                  color: @dart-light-blue;
                }
              }
            }
          }
        }

        .label {
          font-family: 'GothamBook', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          color: @dart-mid-gray;
          margin-bottom: 6px;
          text-align: center;
        }
      }
    }
  }

  &.dark {
    background-color: @dart-dark;

    .remove-wrapper {
      .dart-icon {
        * {
          fill: white;
        }
      }
    }

    .cc-info {
      .left {
        .top {
          margin-top: 6px;

          span {
            border-right: 1px solid @dart-mid-gray;
            font-family: 'GothamBook', sans-serif;
            font-weight: 325;
            font-size: 16px;
            line-height: 16px;
            color: white;
            white-space: nowrap;

            &.bold {
              font-family: 'GothamRegular', sans-serif;
              font-weight: 400;
              text-transform: uppercase;
            }
          }
        }

        .bottom {
          white-space: nowrap;

          span {
            color: white;
            border-right: 1px solid @dart-mid-gray;
          }
        }
      }

      .right {
        .value {
          color: white;
          font-family: 'GothamBook', sans-serif;
          font-weight: 325;
          font-size: 20px;
          line-height: 30px;

          &.editable {
            border-bottom: 1px solid white;
            min-width: 42px;
            text-align: center;
            padding: 0;

            .dart-editable-cell {
              cursor: pointer;
            }

            .edit-mode-wrapper {
              height: 40px;
            }
          }

          &.multiple {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            column-gap: 8px;

            .value-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              flex: 1;
              width: 50px;

              > span {
                font-family: 'GothamBook', sans-serif;
                font-weight: 325;
                font-size: 12px;
                line-height: 18px;

                &:first-child {
                  color: @dart-light-blue;
                }

                &:last-child {
                  color: white;
                }
              }
            }
          }
        }

        .label {
          font-family: 'GothamBook', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          color: #FFFFFF;
          margin-bottom: 6px;
          text-align: center;
        }
      }
    }
  }

  .cc-info {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .left {
      padding: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .top {
        span {
          font-family: 'GothamBook', sans-serif;
          font-weight: 450;
          font-size: 12px;
          line-height: 12px;
          padding: 0 8px;
          white-space: nowrap;

          &.bold {
            font-family: 'GothamRegular', sans-serif;
            text-transform: uppercase;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
            border-right: none;
          }
        }
      }

      .bottom {
        span {
          font-family: 'GothamBook', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          padding: 0 8px;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
            border-right: none;
          }
        }
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 24px;
      padding-right: 0;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .value {
        font-family: 'GothamBook', sans-serif;
        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        padding: 6px 10px;

        &.flagged {
          background: rgba(239, 25, 25, 0.5);
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .label {
        font-family: 'GothamBook', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        &.margin-0{
          margin-bottom: 0;
        }
      }

      .vertical-splitter {
        width: 1px;
        height: 38px;
        background-color: @dart-mid-gray;
        margin-bottom: -30px;
      }
    }
  }

  .remove-wrapper {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";