.store-selection-layout {

}

.store-selection-modal{
  .ant-modal {
    height: calc(100vh - 106px);
    top: 53px;
    .ant-modal-content {
      height: 100%;
      padding: 70px 32px;
      border-radius: 17px;
      .ant-modal-body{
        height: 100%;
      }
    }
    .modal-title {
      margin-bottom: 34px;

      h1 {
        font-family: 'GothamBold', sans-serif;

        font-weight: 450;
        font-size: 24px;
        line-height: 24px;
        text-transform: uppercase;
        color: @dart-dark;
        text-align: center;
        margin-bottom: 8px;
      }

      h3 {
        font-family: 'GothamLight', sans-serif;

        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        color: @dart-dark;
        text-align: center;
      }
    }
    .table-wrapper{
      position: relative;
      .dart-button{
        position: absolute;
        right: 0;
        top: -50px;
        z-index: 9;
        text-transform: uppercase;
      }
      .ant-select-selection-overflow-item-suffix{
        display: none;
      }
      .ant-select-multiple .ant-select-selection-item{
        background: transparent;
        border: transparent;
      }
      .ant-select-selector{
        width: fit-content;
      }
      .dart-compact-select{
        &:not(.all-selected){
          .ant-select-selection-placeholder,
          .ant-select-selection-item-content {
            color: @dart-primary;
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";