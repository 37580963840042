.allocation-layout {
  .header-split-hr {
    margin-top: 22px;
    margin-bottom: 20px;
  }

  .dart-page-title {
    .center {
      .dart-icon {
        margin-top: -4px;
      }
    }
  }

  .newness-filters.compact {
    margin-bottom: 0;

    .ant-form {
      display: flex;
      align-items: center;
      column-gap: 48px;

      .newness-pill-filters-block {
        margin-top: 0;
        margin-bottom: 0;

        .form-group-or, .form-group-and {
          display: none;
        }
      }

      .newness-dropdown-filters-block {
        margin-top: 4px;
        margin-bottom: 0;

        * {
          text-transform: none;
        }
      }
    }
  }

  .newness-filters {
    .newness-pill-filters-block {
      margin-top: 12px;
      column-gap: 0;
      row-gap: 0;
      gap: 0;
      justify-content: center;

      > div > span {
        display: none;
      }

      .dart-pill-select.disabled .pill.selected {
        background-color: @dart-primary;
        cursor: default;
      }

      > div:last-child {
        .dart-pill-select.disabled {
          .pill.selected:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .newness-dropdown-filters-block {
      margin-bottom: 30px;
    }

    &.compact {
      .newness-dropdown-filters-block {
        .ant-form-item:first-child {
          .dart-compact-select {
            .ant-select-selector {
              padding-left: 0;
            }
          }
        }
      }
    }
  }


  hr {
    border: none;
    border-bottom: 1px solid #E1E2E3;
  }
}

// used for top header
.main-layout-content {
  .allocation-layout{
    padding: 0 64px;
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";