.store-profile-layout {
  max-width: 603px;
  margin: 0 auto;
  padding-bottom: 64px;
  // For drag select not to appear above header
  .dart-drag-select__multi-value{
    z-index: 19 !important;
  }
  // For drag select menu not to appear above header
  .dart-drag-select__menu {
    z-index: 19 !important;
    width: 97%;
    margin-left: 1.5%;
  }
  .ant-form-item-label{
    display: block;
    ::before,
    :before,
    :after{
      display: none !important;
    }
  }
  .splitter {
    border: none;
    border-bottom: 1px solid @dart-gray;
    margin: 0;
  }
}

.store-profile-layout-wrap{
  max-height: calc(100vh - 94px);
  overflow-x: hidden;
  overflow-y: auto;
}

// used for top header
.main-layout-content {
  .store-profile-layout-wrap{
    padding: 0 64px;
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";