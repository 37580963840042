.dart-pill-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 8px;
  &.disabled{
    .pill {
      cursor: not-allowed;
      background-color: @dart-gray;
      color: @dart-dark;
      &:after{
        display: none;
      }
      &.selected{
        background-color: fade(@dart-primary, 20%);
        color: white;
      }
    }
  }
  .pill {
    cursor: pointer;
    width: fit-content;
    background: @dart-light;
    font-family: 'GothamBold', sans-serif;
    font-weight: 800;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    border-radius: @dart-border-radius-lg;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    border: none;
    user-select: none;
    &:focus-visible{
      outline: none;
    }
    &.selected{
      background-color: @dart-primary;
      color: white;
      .count{
        color: white;
      }
      &.disabled {
        .count{
          color: @dart-primary;
        }
      }
    }
    &.disabled{
      cursor: not-allowed;
      background-color: @dart-gray;
      color: @dart-dark;
      &:after{
        display: none;
      }
    }
  }
  .count{
    color: @dart-primary;
    padding-left: 3px;
    margin-top: -1px;
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";