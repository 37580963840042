.dart-editable-cell {
  transition: 0.2s;
  .view-mode-wrapper{
    display: flex;
    position: relative;
    width: 80px;
    justify-content: center;
    transition: 0.2s;
    height: 40px;
    align-items: center;
    .dart-editable-cell-hover-icon{
      cursor: pointer;
      opacity: 0;
      margin-left: 4px;
      margin-right: -16px;
      transition: 0.2s;
      font-size: 16px;
    }
    &:hover {
      .dart-editable-cell-hover-icon{
        opacity: 1;
        margin-right: -16px;
        transition: 0.2s;
      }
    }
  }
  .edit-mode-wrapper{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    column-gap: 6px;
    .icon-wrap{
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      border-radius: 3px;
      &:hover{
        background: rgba(0, 0, 0, 0.1);
      }
    }
    input {
      width: 80px;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      text-align: center;
      border: none;
      border-bottom: 1px solid @dart-gray;
      outline: none !important;
      box-shadow: none !important;
    }
    .dart-editable-cell-icon{
      font-size: 16px;
      &.submit{
        * {
          fill: @dart-secondary;
        }
      }
      &.cancel{
        font-size: 14px;
        transform: flipY(1);
      }
    }
  }
  &.has-error{
    .edit-mode-wrapper{
      input {
        border-bottom: 1px solid @dart-danger !important;
      }
      .dart-editable-cell-icon{
        font-size: 16px;
        &.submit{
          * {
            fill: @dart-danger;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";