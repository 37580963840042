.review-ccs-wrapper {
  height: calc(100vh - 312px);
  overflow: auto auto;
  position: relative;
  .loader{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 60%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dart-table .BaseTable__row:hover, .dart-table .BaseTable__row--hovered {
    background-color: transparent !important;
  }

  &.store {
    .child-row-renderer {
      .BaseTable__header{
        display: none;
      }
      .dart-table .BaseTable__row {
        height: 128px !important;
      }

      .review-ccs-table-cell {
        padding-top: 64px;
      }

      .inline-header {
        border-bottom: 1px solid #F6F6F6;
        top: 28px;
      }
    }
  }

  .inline-header {
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    width: 100%;
    padding-bottom: 14px;
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 12px;
    line-height: 18px;
    color: @dart-dark;
  }

  .review-ccs-table {
    .review-table-cell.uuid-parent-cell {
      padding-left: 13px;
      font-family: 'GothamBold', sans-serif;

      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      color: @dart-dark;
      position: relative;

      .uuid {
        font-family: 'GothamBold', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.highlight{
          color: @dart-secondary;
        }
      }

      .date {
        font-family: 'GothamLight', sans-serif;
        width: fit-content;
        white-space: nowrap;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        position: absolute;
        bottom: -28px;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-tag{
          display: flex;
          justify-content: center;
          align-content: center;
          margin-right: 6px;
        }
      }
    }

    .review-table-cell {
      font-family: 'GothamLight', sans-serif;

      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: @dart-dark;
    }

    .review-ccs-table-cell.uuid-child-cell {
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: @dart-dark;
      display: flex;
      .ant-tag{
        display: flex;
        justify-content: center;
        align-content: center;
      }
      &.highlight{
        color: @dart-secondary;
      }
    }

    .dart-table-expand-icon {
      position: initial;
    }

    .review-ccs-table-cell {
      font-family: 'GothamLight', sans-serif;

      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: @dart-dark;

      .inline-header {
        font-family: 'GothamLight', sans-serif;

        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  &.ccs {
    .expanded-row-child {
      .BaseTable__row-cell {
        border-right: 1px solid @dart-gray;
      }
      .BaseTable__header-cell {
        border-right: 1px solid @dart-gray;
      }
    }
    .review-ccs-table {
      .BaseTable__row--depth-0 {
        height: 110px !important;
        &.BaseTable__row--expanded{
          border-bottom: none;
        }
      }
      .child-row-renderer {
        padding: 32px 16px 32px 0;
        box-sizing: content-box;
      }
    }
  }

  .split-table-header {
    width: 100%;
    height: 60px;
    background: #F6F6F6;
    border-radius: 16px;
    line-height: 60px;
    text-align: center;
    font-family: 'GothamBold', sans-serif;

    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #E87324;
    margin-top: 42px;
    margin-bottom: 20px;
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";