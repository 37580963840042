.flow-doc-preview-block {
  .flow-doc-preview-cell.cc-cell {
    display: flex;
    justify-content: center;
    width: 100%;
    .error-tooltip-icon {
      position: absolute;
      right: 0;
    }
    .dart-checkbox {
      position: absolute;
      left: 0;
    }
  }
  .dart-table .select-toggle-button{
    top: 8px;
    left: 0px;
    background-color: transparent;
    color: white;
    width: 75px;
    text-align: center;
    padding: 0;
  }

  .BaseTable__body {
    height: calc(100vh - 435px) !important;
  }

  .dart-table.dark {
    &.border-top{
      border-top: none;
    }
    .BaseTable__table-frozen-left .BaseTable__header{
      height: 34px !important;
    }
  }
  .BaseTable__table-frozen-left {
    top: -1px;
    min-height: calc(100vh - 400px) !important;
    .BaseTable__body {
      height: calc(100vh - 435px) !important;
    }
  }

  &.windows {
    .BaseTable__table-frozen-left {
      .BaseTable__body {
        padding-bottom: 17px; // Scroll fix for windows
      }
    }
  }

  .BaseTable__row {
    font-family: 'GothamRegular', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: @dart-dark;

    .BaseTable__row-cell{
      .dart-input input {
        height: 36px;
      }
      .dart-datepicker .dart-datepicker-target{
        height: 36px;
      }
      .map-break-picker{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 37px;
        .ant-picker-suffix{
          margin-left: -58px;
        }
        span {
          padding-left: 15px;
        }
        .ant-picker.dart-datepicker-target{
          width: 58px !important;
          input {
            opacity: 0;
          }
        }
      }
    }

    .flow-doc-preview-cell{
      .ant-select.ant-select-single{
        max-width: 200px;
      }
      &.has-error{
        .ant-select{
          background-color: @dart-danger;
          border-radius: 8px;
          * {
            color: white;
            fill: white;
          }
        }
        .dart-input {
          input {
            background-color: @dart-danger !important;
            color: white;
          }
        }
        .ant-picker.dart-datepicker-target{
          background-color: @dart-danger !important;
          color: white;
          .ant-picker-suffix *, input {
            color: white;
            fill: white;
          }
        }
      }
    }

    .cc-cell {
      position: relative;
      color: @dart-dark;
      display: flex;
      column-gap: 24px;
      align-items: center;

      .cc {
        font-family: 'GothamBold', sans-serif;

        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";