.user-list-layout {
  .dart-page-title{
    height: 72px;
    margin-top: 0;
    z-index: 1;
    .center{
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: @dart-dark;
    }
  }
}

// used for top header
.main-layout-content {
  .user-list-layout{
    padding-top: 128px;
    .container-content{
      padding: 0 64px;
    }
    .dart-page-title{
      background-color: @dart-light;
      position: fixed;
      width: 100vw;
      top: 94px;
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";