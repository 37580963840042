.api-logs-layout {
  .api-logs-list-filters {
    margin-bottom: 34px;
    .api-logs-list-filters-wrapper {
      margin-top: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      .ant-form-item{
        margin-bottom: 0;
      }
    }
  }

  hr {
    border: 0;
    border-bottom: 1px solid #E1E2E3;
  }
}

// used for top header
.main-layout-content {
  .api-logs-layout{
    padding: 0 64px;
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";