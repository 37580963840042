.size-run-import-widget-single {
  border-radius: 16px;
  background-color: @dart-light;
  padding: 16px;
  margin-bottom: 16px;

  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;

    .left {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      column-gap: 16px;
    }

    .title {
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      color: @dart-dark;
    }

    .extra {
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: @dart-dark;
      display: flex;
      align-items: center;
      column-gap: 16px;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .expand-content.visible {
      margin-top: 0;
    }

    .expand-content {
      .BaseTable__body {
        height: 150px !important;
      }

      .BaseTable__table-frozen-left {
        min-height: 184px !important;
      }

      .size-profile-editable-cell {
        border: 1px solid @dart-gray-alt;
        border-top: none;
        padding: 0;
        position: relative;

        .is-sister-store{
          position: absolute;
          bottom: 6px;
          right: 4px;
          font-size: 12px;
          line-height: 18px;
          font-weight: 600;
        }

        .view-mode-wrapper {
          cursor: pointer;
          height: 22px;
          color: @dart-dark;
        }

        .contribution-value {
          color: black;
          font-weight: 600;

          small {
            color: @dart-mid-gray;
            position: absolute;
            left: 2px;
            bottom: -1px;
          }
        }

        &:hover {
          .view-mode-wrapper {
            text-decoration: underline;
          }
        }

        .dart-editable-cell {
          cursor: pointer;
        }

        &.disable-editing {
          .dart-editable-cell {
            cursor: initial;
          }
        }

        .dart-editable-cell:hover .view-mode-wrapper {
          text-decoration: underline;
        }
      }

      .size-profile-fixed-cell {
        border: 1px solid @dart-gray-alt;
        border-top: none;
      }

      .size-run-import-widget-table-cell:not(.frozen) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .size-run-import-widget-table-cell.updated {
        background-color: #E8F4EF;
        height: 100%;
        width: 100%;
      }

      .size-run-import-widget-table-cell.flagged {
        background-color: #FFEAEA !important;
        height: 100%;
        width: 100%;
      }

      .size-run-import-widget-table-cell.frozen {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          column-gap: 8px;

          span {
            font-family: 'GothamLight', sans-serif;

            font-weight: 325;
            font-size: 12px;
            line-height: 18px;
            color: @dart-dark;

            &.vertical-splitter {
              height: 16px;
              width: 1px;
              background-color: @dart-gray;
              display: block;
            }

            &.store-status {
              width: 8px;
              height: 8px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

.size-run-import-widget-modal-container {
  .modal-content-search {
    width: 333px;
    margin: 0 auto 20px;

    .ant-input {
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: #333E47;
      background: white !important;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      width: 300px;
      border-color: #E87324 !important;
      box-shadow: none !important;
    }

    .ant-input-group-addon {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      button {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        height: 34px;
        box-shadow: none;
        background-color: #E87324;
        border-color: #E87324 !important;

        svg * {
          fill: white;
        }
      }
    }
  }

  .ant-modal {
    max-width: 100%;
  }

  &.dart-prompt .ant-modal-content .ant-modal-header .head-wrapper {
    margin-top: 0;
    flex-direction: row;
    justify-content: center;
    column-gap: 16px;
    margin-left: -16px;

    .title {
      font-size: 32px;
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";