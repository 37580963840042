.cc-finder-widget-wrapper {
  position: relative;
  .cc-finder-widget {
    background: @dart-dark;
    border-radius: 16px;
    padding: 24px 16px 16px 16px;

    .header {
      position: relative;
      margin-bottom: 24px;

      .back-button {
        position: absolute;
        left: 0;
        top: -2px;
        border: 0 !important;

        * {
          fill: white;
        }
      }

      .title {
        font-family: 'GothamRegular', sans-serif;
        font-style: normal;
        font-weight: 450;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
      }
    }

    .input-wrapper {
      position: relative;

      .add-button {
        position: absolute;
        right: 8px;
        top: 24px;
        border: 0;
        border-left: 1px solid @dart-gray;
        border-radius: 0;
        width: 72px;
        padding-left: 24px;
        padding-right: 24px;
        height: 40px;
        box-shadow: none;

        &.disabled{
          .dart-icon {
            * {
              fill: @dart-gray;
            }
          }
        }

        .dart-icon {
          margin-top: 4px;

          * {
            fill: @dart-dark;
          }
        }
      }
    }

    .ant-select {
      border-radius: 8px;
      overflow: hidden;
    }

    .ant-select-selector {
      height: 140px;
      border-radius: 8px !important;
      padding: 24px 86px 24px 24px;
      position: relative;
      overflow-y: auto;
      align-items: flex-start !important;
    }

    .ant-select-selection-placeholder {
      top: 36px;
      left: 32px;
      right: 32px;
      color: @dart-mid-gray;
    }

    .ant-select-selection-item {
      height: 40px;
      background: #F6F6F6;
      border-radius: 24px;
      padding: 8px 12px;

      &.invalid {
        background-color: @dart-danger;

        .ant-select-selection-item-content {
          color: white;
        }

        .ant-select-selection-item-remove {
          * {
            fill: white;
          }
        }
      }

      .ant-select-selection-item-content {
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 16px;
        text-align: center;
        color: @dart-dark;
        line-height: 24px;
      }

      .ant-select-selection-item-remove {
        padding-top: 6px;
        margin-left: 6px;

        * {
          fill: @dart-dark;
          font-size: 9px;
        }
      }

      .ant-select-selection-overflow {
        height: 140px;
        flex-wrap: wrap;
        column-gap: 8px;
        row-gap: 4px;
        padding-right: 64px;
      }
    }
  }
  .error-message{
    display: flex;
    column-gap: 6px;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: -30px;
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";