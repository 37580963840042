.user-page-header {
  padding-top: 48px;
  padding-bottom: 0px;
  font-family: 'GothamLight', sans-serif;
  font-weight: 325;
  font-size: 12px;
  line-height: 18px;
  position: sticky;
  top: 0;
  z-index: 30;
  background: white;

  .top-section {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .back-button {
      border: none;
      box-shadow: none;
      margin-left: -6px;

      &:hover,
      &:focus {
        color: @dart-dark;
      }
    }

    .delete-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 12px;
      border: none;
      box-shadow: none;
      margin-right: -12px;

      span:focus,
      span:hover,
      &:hover,
      &:focus {
        color: @dart-dark;
      }

      p {
        color: #000000;
        margin: 0;
        padding: 0;
      }
      .anticon{
        padding-top: 2px;
      }
    }
  }

  .bottom-section {
    margin-bottom: 24px;

    .user-name{
      max-width: calc(100% - 88px);
    }

    .user-name span {
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 24px;
      line-height: 24px;
      text-transform: uppercase;
      color: @dart-dark;
    }

    .you-indicator{
      position: absolute;
      top: 52px;
      right: 15px;
      margin: 0 auto;
      padding: 2px 8px;
      width: 40px;
      border-radius: 8px;
      background-color: @dart-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 12px;
      font-family: 'GothamBold', sans-serif;
      font-weight: 400;
    }

    .avatar{
      position: absolute;
      right: 0;
      bottom: 30px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 1px solid @dart-mid-gray;
      background-color: @dart-light;
    }
  }
}

@hack: true;@import "/codebuild/output/src1831344493/src/src/configs/variables.less";